/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.css";
@import "toastr.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

body {
  padding-top: 0rem;
  // background-color: #183d8a;
  background-color: #000;
}
.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}
.card-title {
  font-weight: bold;
}


html, body { height: 98%; margin-right: calc(-1 * (100vw - 100%));}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
